.settings {
  width: 100%;
  min-height: 100%;
  background: var(--bg-gray-level2);
  color: var(--fg-gray-level2);
}
.settingsInner {
  max-width: var(--width-app-maximum);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;
}
.settings h2 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
}
.settings p {
  margin: 0 0 1rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}
.settings hr {
  margin: 1rem 0;
}
.settings a {
  color: var(--fg-pickup-level3);
}
.settings a[target="_blank"]:after {
  width: 1.4rem;
  height: 1.4rem;
  content: url('/resources/blank.svg');
  margin: -2px 0 0 0;
  display: inline-block;
  vertical-align: middle;
}
