.introduction {
  min-height: 7rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fcfcfc;
  overflow: hidden;
  flex: 1 1 100%;
}
.introductionMessage {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.introductionMessageIcon {
  flex: 0 0 100px;
  padding-right: 20px;
}
.introductionMessageText {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.75rem;
  background: var(--bg-main-level1);
  border-radius: 8px;
  color: var(--fg-main-level2);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;
}
.introductionMessageText:before {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  left: -7px;
  top: calc(50% - 7px);
  border-right: 7px solid #e2f7ff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.introductionMessageText span {
  font-weight: bold;
}
