@value small: max-width: 767px;
@value large: min-width: 768px;
@value contentsWidth: 980px;

.container {
  width: 100%;
  box-sizing: border-box;
  background: #f9f9f9;
  color: #333;
}

/**
 * heroArea
 */
.heroArea {
  margin: 0;
  padding-bottom: 2rem;
}

.heroAreaInner {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;
}
@media (large) {
  .heroAreaInner {
    max-width: contentsWidth;
  }
}

.heroTitle {
  margin-bottom: 3rem;
}

.heroTitleHeading {
  margin: 0 0 0.5rem 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: bottom;
}

.heroTitleHeadingImage {
  margin-right: 0.25rem;
}

.heroTitleHeadingExtra {
  margin: 0;
  color: #4cb4d4;
  font-weight: bold;
  font-size: 0.875rem;
}

.heroBlock {

}
@media (large) {
  .heroBlock {
    max-width: contentsWidth;
    min-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-right: calc(322px + 2rem);
  }
}

.heroCatch {
  margin: 0;
  letter-spacing: 0.125rem;
  line-height: 2.0;
}
@media (small) {
  .heroCatch {
    margin-top: -0.625rem;
    margin-bottom: calc(4rem - 0.625rem);
    font-size: 1.25rem;
  }
}
@media (large) {
  .heroCatch {
    margin-bottom: 3rem;
    font-size: 2rem;
  }
}

.heroCatchFirst {
  border-bottom: 0.25rem solid #dad20d;
}

.heroImage {
  max-width: 100%;
}
@media (small) {
  .heroImage {
    width: 100%;
    margin-bottom: 2rem;
  }
}
@media (large) {
  .heroImage {
    width: 317px;
    position: absolute;
    top: 0;
    right: 1rem;
  }
}

.heroNavigation {
  max-width: 100%;
  height: 4rem;
  box-sizing: border-box;
  display: block;
  background: #0098f1;
  border: 0;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
@media (small) {
  .heroNavigation {
    width: 100%;
  }
}
@media (large) {
  .heroNavigation {
    width: 340px;
  }
}

/**
 * stepsArea
 */
.stepsArea {
  margin: 0;
  background: #e2f7ff;
  color: #00749a;
}

.stepsAreaInner {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 3rem 1rem;
}
@media (large) {
  .stepsAreaInner {
    max-width: contentsWidth;
  }
}

.stepsBlock {
  position: relative;
  margin: 0 0 4rem 0;
}
@media (large) {
  .stepsBlock {
    min-height: 481px;
    padding-right: calc(270px + 3rem);
  }
}

.stepsNo {
  display: block;
  color: #7ad0f2;
  margin: 0;
}
@media (small) {
  .stepsNo {
    font-size: 5rem;
    margin-bottom: 1rem;
  }
}
@media (large) {
  .stepsNo {
    font-size: 10rem;
    line-height: 8rem;
    margin-bottom: 1.5rem;
  }
}

.stepsSubTitle {
  margin: 0;
}
@media (small) {
  .stepsSubTitle {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  .stepsSubTitle br {
    display: none;
  }
}
@media (large) {
  .stepsSubTitle {
    font-size: 1.75rem;
    line-height: 2;
  }
}

.stepsImage {
  width: 270px;
}
@media (small) {
  .stepsImage {
    margin-bottom: 2rem;
  }
}
@media (large) {
  .stepsImage {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.stepsDescription {
  margin: 0;
}
@media (small) {
  .stepsDescription {
    font-size: 0.875rem;
    line-height: 1.8;
  }
  .stepsDescription br {
    display: none;
  }
}
@media (large) {
  .stepsDescription {
    padding: 1.5rem 0 0 0;
    font-size: 1.125rem;
    line-height: 2.3;
  }
}

@media (large) {
  .stepsBlock2 {
    padding-right: 0;
    padding-left: calc(270px + 3rem);
  }
}

@media (large) {
  .stepsBlock2 .stepsImage {
    right: auto;
    left: 0;
  }
}
@media (large) {
  .stepsBlock4 {
    padding-right: 0;
    padding-left: calc(270px + 3rem);
  }
}
@media (large) {
  .stepsBlock4 .stepsImage {
    right: auto;
    left: 0;
  }
}

/**
 * detailsArea
 */
.detailsArea {
  margin: 0;
  background: #7bdeff;
  color: #00749a;
}

.detailsAreaInner {
  box-sizing: border-box;
  margin: 0 auto;
}
@media (small) {
  .detailsAreaInner {
    padding: 3rem 1rem;
  }
}
@media (large) {
  .detailsAreaInner {
    max-width: contentsWidth;
    padding: 6rem 1rem;
  }
}

@media (small) {
  .detailsTitle {
    margin: 0 0 3rem 0;
    font-size: 1.5rem;
    line-height: 1.8;
  }
}
@media (large) {
  .detailsTitle {
    margin: 0 0 6rem 0;
    font-size: 2rem;
    line-height: 1;
  }
}

.detailsHeadingImage {
  margin-bottom: -0.125rem;
  margin-right: 0.25rem;
}

.detailsBlock {
  background: #fff;
}
@media (small) {
  .detailsBlock {
    padding: 1rem;
    border-radius: 0.5rem;
  }
}
@media (large) {
  .detailsBlock {
    display: flex;
    padding: 3rem;
    border-radius: 1.5rem;
  }
}

@media (small) {
  .detailsImage {
    width: auto;
    max-width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
}
@media (large) {
  .detailsImage {
    width: 270px;
  }
}

.detailsTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
@media (small) {
  .detailsTextBox {
    margin-bottom: 1rem;
  }
}
@media (large) {
  .detailsTextBox {
    margin-left: 3rem;
  }
}

.detailsList {
  list-style: none;
  margin: 0;
  padding-left: 1.5rem;
}

.detailsListItem {
  margin: 0 0 1rem 0;
  text-indent: -0.75rem;
  font-size: 1rem;
}

.detailsListItemText {
  margin-left: 0.5rem;
}

.detailsMessage {
  display: flex;
  align-items: center;
  margin: 0;
}
@media (large) {
  .detailsMessage {
    margin-top: 3rem;
  }
}

@media (small) {
  .detailsMessageIcon {
    display: none;
  }
}
@media (large) {
  .detailsMessageIcon {
    flex: 0 0 100px;
    padding-right: 2rem;
  }
}

.detailsMessageText {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.75rem;
  background: #e2f7ff;
  border-radius: 0.5rem;
  color: #46b1d2;
  font-weight: bold;
  line-height: 1.5;
}
@media (small) {
  .detailsMessageText {
    font-size: 1rem;
  }
}
@media (large) {
  .detailsMessageText {
    font-size: 1.33rem;
  }
}
.detailsMessageText:before {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  left: -7px;
  top: calc(50% - 7px);
  border-right: 7px solid #e2f7ff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
@media (small) {
  .detailsMessageText:before {
    display: none;
  }
}

/**
 * landing
 */
.landingArea {
  margin: 0;
}

.landingAreaInner {
  box-sizing: border-box;
  margin: 0 auto;
}
@media (small) {
  .landingAreaInner {
    padding: 3rem 1rem;
  }
}
@media (large) {
  .landingAreaInner {
    max-width: contentsWidth;
    padding: 3rem 1rem 6rem 1rem;
  }
}

.landingTitle {
  margin-bottom: 3rem;
}
@media (small) {
  .landingTitle {
    font-size: 1.25rem;
    line-height: 2;
  }
  .landingTitle br {
    display: none;
  }
}
@media (large) {
  .landingTitle {
    font-size: 1.75rem;
    line-height: 2.2;
  }
}

.landingTitlePoint {
  border-bottom: 0.25rem solid #dad20d;
}

.landingNavigation {
  max-width: 100%;
  height: 4rem;
  box-sizing: border-box;
  display: block;
  background: #0098f1;
  border: 0;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
@media (small) {
  .landingNavigation {
    width: 100%;
  }
}
@media (large) {
  .landingNavigation {
    width: 340px;
  }
}

.landingMessage {
  max-width: 800px;
  display: flex;
  align-items: center;
  margin: 0 auto 1.5rem auto;
}

@media (small) {
  .landingMessageIcon {
    display: none;
  }
}
@media (large) {
  .landingMessageIcon {
    flex: 0 0 100px;
    padding-right: 2rem;
  }
}

.landingMessageText {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.75rem;
  background: #e2f7ff;
  border-radius: 0.5rem;
  color: #46b1d2;
  font-weight: bold;
  line-height: 1.5;
}
@media (small) {
  .landingMessageText {
    font-size: 1rem;
  }
  .landingMessageText br {
    display: none;
  }
}
@media (large) {
  .landingMessageText {
    font-size: 1.33rem;
  }
}
.landingMessageText:before {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  left: -7px;
  top: calc(50% - 7px);
  border-right: 7px solid #e2f7ff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
@media (small) {
  .landingMessageText:before {
    display: none;
  }
}

.landingSeparator {
  border: 0;
  border-top: 1px dashed #999;
}
@media (small) {
  .landingSeparator {
    margin: 3rem 0;
  }
}
@media (large) {
  .landingSeparator {
    margin: 5rem 0;
  }
}

.landingSNSList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  margin-left: -1.5rem;
  padding: 0;
  list-style: none;
}

.landingSNSListItem {
  margin: 0;
  margin-left: 1.5rem;
}

.landingSNSButtonIcon {
  width: 2rem;
  height: 2rem;
}

.landingSNSButtonLINE {
  color: #00b900;
}

.landingSNSButtonTwitter {
  color: #1ea0f2;
}

.landingSNSButtonFacebook {
  color: #4267b2;
}

/**
 * footer
 */
.footer {
  width: 100%;
  box-sizing: border-box;
  background: #555;
  color: #ddd;
}
@media (small) {
  .footer {
    padding: 2rem 1rem;
  }
}
@media (large) {
  .footer {
    padding: 3rem;
  }
}

.footerInner {
  box-sizing: border-box;
  margin: 0 auto;
}
@media (large) {
  .footerInner {
    max-width: contentsWidth;
  }
}

.footerTitle {
  margin: 0 0 2rem 0;
  color: #fff;
  font-size: 1.25rem;
}

.footerColumns {
  margin-bottom: 2rem;
}
@media (large) {
  .footerColumns {
    display: flex;
    align-items: stretch;
  }
}

.footerColumn {
  box-sizing: border-box;
  flex: 0 1 33%;
  padding-right: 2rem;
}

.footerList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footerItem {
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.footerLink {
  color: #ddd;
  text-decoration: none;
}

.footerNotice {
  margin: 0;
}

.footerNoticeText {
  margin: 0 0 1rem 0;
  font-size: 0.75rem;
  line-height: 2.0;
  letter-spacing: 0.0625rem;
}