.menu {
  height: 3rem;
  position: relative;
  z-index: var(--depth-basic);
  background: #333;
  border-top: 1px solid #999;
}
.menuList {
  width: 100%;
  max-width: var(--width-app-maximum);
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 0  auto;
  padding: 0;
  border-right: 1px solid #999;
  list-style: none;
}
.menuItem {
  flex: 0 1 100%;
  background: #333;
  border-left: 1px solid #999;
  color: #fff;
}
.menuItemInner {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
}
.menuItemDisabled .menuItemInner {
  opacity: 0.25;
}
.menuItemIcon {
  width: 1.25rem !important;
  height: 1.25rem;
  padding-bottom: 0.125rem;
}
.menuItemText {
  font-size: 0.75rem;
  text-align: center;
}
