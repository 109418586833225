.mismatchedMessage {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  box-shadow: none;
}
.mismatchedMessageIcon {
  flex: 0 0 100px;
  padding-right: 20px;
}
.mismatchedMessageText {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.75rem;
  background: #e2f7ff;
  border-radius: 8px;
  color: #46b1d2;
  line-height: 1.4;
}
.mismatchedMessageText:before {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  left: -7px;
  top: calc(50% - 7px);
  border-right: 7px solid #e2f7ff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
