.container {
  width: 100%;
  max-width: var(--width-app-maximum);
  min-height: 100%;
  position: relative;
  z-index: var(--depth-basic);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: #888;
}
.inputArea {
  position: relative;
  padding: 0.5rem 3rem 0.5rem 0.5rem;
  background: var(--bg-gray-level2);
}

.replaceButton {
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  z-index: var(--depth-basic);
  display: inline-block;
  margin: auto 0;
  padding: 0;
  background: var(--bg-main-level4);
  border: 0;
  border-radius: 1rem;
  color: #fff;
  font-size: 1rem;
}
.replaceButton[disabled] {
  background: #ccc;
}
.replaceButton > svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.replaceButtonLabel {
  letter-spacing: -1px;
}
.replaceButtonIcon {
  margin-left: 0.25rem;
}

.submitArea {
  padding: 0.5rem;
  background: var(--bg-gray-level2);
}
.submitButton {
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  display: block;
  background: var(--bg-main-level4);
  border: 0;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
}
.submitButton[disabled] {
  background: #ccc;
}
.submitButtonIcon {
  margin-left: 0.33rem;
}
