.help {
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: var(--bg-pickup-level3);
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  padding: 0;
  border-radius: 0.5rem;
  box-shadow: 1px 1px var(--bg-pickup-level3-shadow);
  line-height: 1.0;
  color: var(--fg-pickup-level3);
  text-align: center;
  font-style: normal;
  font-weight: bold;
  vertical-align: baseline;
  cursor: pointer;
}
.helpModal {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--depth-help);
  display: none;
  box-sizing: border-box;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
}
.helpModalEnter {
  display: block;
  opacity: 0;
}
.helpModalEnterActive {
  display: block;
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.helpModalEnterDone {
  display: block;
  opacity: 1;
}
.helpModalExit {
  display: block;
  opacity: 1;
}
.helpModalExitActive {
  display: block;
  opacity: 0;
  transition: opacity 150ms ease-out;
}
.helpModalInner {
  width: auto;
  min-height: 100%;
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  background: #e2f7ff;
  border-radius: 8px;
  color: #46b1d2;
  text-align: left;
}
.helpModalHeader {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.helpModalIcon {
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #fff;
  padding: 0;
  border-radius: 1.2rem;
  box-shadow: 1px 1px #46b1d2;
  line-height: 1.0;
  color: #46b1d2;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: bold;
  vertical-align: baseline;
}
.helpModalIcon:before,
.helpModalIcon:after {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  right: -9px;
  top: calc(50% - 4px);
  border-left: 10px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.helpModalIcon:before {
  right: -10px;
  top: calc(50% - 3px);
  border-left-color: #46b1d2;
}
.helpModalCharacter {
  margin-left: 1rem;
}
.helpModalText {
  flex: 1 1 auto;
  margin: 0 0 1rem 0;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.7;
}
.helpModalButton {
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5cd0f6;
  border: 0;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.33rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-appearance: none;
}
