html {
  height: 100%;
}
body {
  height: 100%;
  color: #333;
  font-family: Helvetica;
}
#root {
  height: 100%;
}
.apps {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform-style: preserve-3d;
}
.pages {
  height: auto;
  min-height: 100%;
}
input, button {
  font-family: Helvetica;
}
img {
  vertical-align: bottom;
}
