.tips {
  height: 2rem;
  flex: 0 0 2rem;
  display: flex;
  box-sizing: border-box;
  padding: 0;
  background: var(--bg-gray-level1);
  border-bottom: 1px solid #ddd;
}
.tipsInner {
  width: var(--width-app-maximum);
  height: calc(2rem - 1px);
  overflow: hidden;
  margin: 0 auto;
}
.tipsDisplay {
  width: 10000px; /* tips を2つ横に並べても十分なスペースのコンテナ */
  height: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
}
.tipsItem {
  min-width: var(--width-app-maximum);
  height: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0 2rem;
  transition: margin-left;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.tipsItem:before {
  width: 2rem;
  height: 1.5rem;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem 0 0 0;
  background: url("/resources/ufo.png") no-repeat 50% 50%;
  background-size: 2rem 1.5rem;
  animation: animateFlowing 2.0s linear 0s infinite;
}
.tipsItemFirst {
  order: 1;
  display: flex;
}
.tipsItemSecond {
  order: 2;
  display: flex;
}
.tipsText {
  width: 100%;
  height: 100%;
  display: block;
  flex: 0 0 100%;
  margin: 0;
  padding: 0;
  color: var(--fg-gray-level2);
  font-size: 1rem;
  line-height: 2rem;
}

@keyframes animateFlowing {
  0% {
    top: 0;
  }
  15% {
    top: 3px;
  }
  25% {
    top: 4px;
  }
  35% {
    top: 3px;
  }
  50% {
    top: 0;
  }
  65% {
    top: -3px;
  }
  75% {
    top: -4px;
  }
  85% {
    top: -3px;
  }
  100% {
    top: 0;
  }
}
