.header {
  height: 3.5rem;
  flex: 0 0 3.5rem;
  position: relative;
  background: #333;
  color: #eee;
  text-align: center;
}
.headerInner {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: var(--width-app-maximum);
  margin: 0 auto;
  padding: 0;
}
.headerHeading {
  flex: 1 1 100%;
  margin: 0;
}
.headerDemo {
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
}
.headerDemoText {
  width: 8.0rem;
  height: 3.5rem;
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  color: var(--fg-pickup-level1);
  font-size: 0.875rem;
  font-weight: bold;
}
.headerHeadingTitle {
  display: block;
  margin: 0 0 0.25rem 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.0;
  vertical-align: middle;
}
.headerHeadingDescription {
  display: block;
  margin: 0;
  color: var(--fg-main-level1);
  font-size: 0.75rem;
  line-height: 1.0;
  font-weight: bold;
}
.headerLang {
  width: 3.5rem;
  height: 3.5rem;
  display: block;
}
.headerLangIcon {
  width: 3.5rem;
  height: 3.5rem;
  box-sizing: border-box;
  display: block;
  padding: 0.5rem;
}
