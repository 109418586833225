.courseResultsClosed {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -100%;
  left: 0;
  z-index: var(--depth-basic);
}
.courseResultsOpened {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: var(--depth-basic);
}
.courseResultsInner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: -100%;
  z-index: var(--depth-basic);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  background: var(--bg-main-level3);
  transition: bottom 300ms cubic-bezier(0.19, 1, 0.22, 1);
}
.courseResultsOpened .courseResultsInner {
  bottom: 0;
}
.courseResultsPanel {
  max-width: var(--width-app-maximum);
  position: relative;
  margin: 0 auto;
  padding: 1rem 1rem 6rem 1rem;
}
.courseResultsHeading {
  margin: 0;
  padding-right: 1.8rem;
  color: var(--fg-main-level3);
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4;
}
.courseResultsContents {
  margin-top: 1rem;
}
.courseResultsTogglebutton {
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  display: block;
  margin-top: 1rem;
  background: var(--bg-main-level5);
  border: 0;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
}
.courseResultsClosebutton {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  z-index: var(--depth-basic);
  right: 0.75rem;
  top: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-main-level5);
  border-radius: 0.9rem;
  color: #fff;
}
