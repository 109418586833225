.candidates {
}
.candidatesText {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.candidatesList {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: var(--depth-basic);
  margin: 2px 0 0 0;
  padding: 0;
  border: 0.25rem solid var(--bg-main-level2);
  list-style: none;
}
.candidatesListItem {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0.25rem;
  background: #fff;
  cursor: pointer;
  color: #555;
  text-align: left;
  outline: 0;
}
.candidatesListItem:nth-child(odd) {
  background: var(--bg-main-level1);
}
.candidatesListItemActive {
  background: rgba(136, 227, 255, 0.8) !important;
  color: #336;
}
.candidatesListItem > li:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}
.candidatesSubText {
  display: block;
  font-size: 0.75rem;
  line-height: 1.2;
}
.candidatesMainText {
  display: block;
  font-size: 1rem;
  line-height: 1.2;
}
