.container {
  position: relative;
}

.input {
  position: relative;
  margin-right: 3rem;
  margin-bottom: 1rem;
}
.inputFormElement {
  width: 100%;
  max-width: 100%;
  height: 2.125rem;
  box-sizing: border-box;
  padding: 0.25rem;
  background-color: #fff;
  border-radius: 0.375rem;
  border: 1px solid #dbdbdb;
  color: #363636;
  font-size: 1rem;
  line-height: 2.0;
  vertical-align: top;
  ime-mode: disabled;
  -webkit-appearance: none;
}
.inputClearButton {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 0.75rem;
  bottom: 0.75rem;
  z-index: var(--depth-basic);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #f0f0f0;
  border: 0;
  border-radius: 0.75rem;
}
.inputClearButton .fa-times,
.inputClearButton svg {
  font-size: 0.875rem;
  color: #777;
}
.choicedArea {
  width: 100%;
  min-height: 2.25rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #fff;
  margin-right: 2.5rem;
  padding: 0.25rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.375rem;
}
.choicedItem {
  display: inline-block;
  margin: 0;
  padding: 0.25rem 0.5rem;
  background: var(--bg-gray-level1);
}
.choicedText {
  display: inline;
  margin: 0;
  font-weight: bold;
  word-break: break-all;
}
.choicedTextEmpty {
  display: inline;
  line-height: 1.6;
  margin: 0;
  padding: 0 0.125rem;
}
.choicedClearButton {
  width: 1rem;
  height: 1rem;
  z-index: var(--depth-basic);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  background: var(--bg-gray-level3);
  border: 0;
  border-radius: 0.5rem;
}
.choicedClearButton .fa-times,
.choicedClearButton svg {
  font-size: 0.75rem;
  color: #777;
}

.modalBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: var(--depth-modal);
  background: rgba(0, 0, 0, 0.8);
}
.modal {
  width: 100%;
  max-width: var(--width-app-maximum);
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: var(--depth-modal);
  margin: 0 auto;
  background: var(--bg-main-level1);
  opacity: 0.9;
  border: 0.25rem solid var(--bg-main-level5);
}
.modalInner {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 15rem 0.5rem;
  overflow: scroll;
}
.modalText {
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  padding-top: 0.5rem;
}
.modalClosebutton {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  z-index: var(--depth-contents);
  right: 0.75rem;
  top: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0098f1;
  border-radius: 0.9rem;
  color: #fff;
}
