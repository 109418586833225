.matchedOverview {
  position: relative;
  margin-top: 0.67rem;
  padding: 0.5rem;
  background: #fff;
  border-radius: 6px;
}
.matchedOverviewItem ~ .matchedOverviewItem {
  margin-top: 2rem;
}
.matchedOverviewCoursenumbers {
  margin-right: -0.5rem;
}
.matchedOverviewCoursenumber {
  width: 3rem;
  height: 3rem;
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: var(--bg-main-level5);
  border-radius: 1.5rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  vertical-align: middle;
  text-decoration: none;
}
.matchedOverviewMessage {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.matchedOverviewMessageIcon {
  flex: 0 0 100px;
  padding-right: 20px;
}
.matchedOverviewMessageText {
  position: relative;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.75rem;
  background: var(--bg-main-level1);
  border-radius: 8px;
  color: var(--fg-main-level2);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;
}
.matchedOverviewMessageText:before {
  position: absolute;
  content: '';
  display: block;
  width: 0;
  height: 0;
  left: -7px;
  top: calc(50% - 7px);
  border-right: 7px solid #e2f7ff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.matchedOverviewMessageText span {
  font-weight: bold;
}
.matchedDetails {
  position: relative;
  margin-top: 1rem;
  padding: 0.5rem;
  background: #fff;
  border-radius: 6px;
}
.matchedDetailsTitle {
  height: 2rem;
  display: flex;
  align-items: center;
  margin: 0;
}
.matchedDetailsTitleCoursenumber {
  width: 2rem;
  height: 2rem;
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background: var(--bg-main-level5);
  border-radius: 1rem;
  color: #fff;
  font-size: 0.88rem;
  font-weight: bold;
  vertical-align: middle;
  text-decoration: none;
}
.matchedDetailsTitleText {
  margin: 0;
  vertical-align: middle;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2;
}
.matchedDetailsList {
  padding-top: 1rem;
}
.matchedDetailsListitem {
  margin-top: 1rem;
  padding: 1rem 0 0 0;
  border-top: 1px solid #c7c7c7;
}
.matchedDetailsListitem:first-child {
  border-top: 0;
  margin-top: 0.5rem;
  padding-top: 0;
}
.matchedDetailsListitem > p {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  line-height: 1.4;
}
.matchedDetailsListitem > a {
  -webkit-appearance: none;
  color: var(--fg-pickup-level2);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.4;
  text-decoration: underline;
  display: inline;
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
}
.matchedDetailsListitem > a[target="_blank"]:after {
  width: 1.4rem;
  height: 1.4rem;
  content: url('/resources/blank.svg');
  margin: -2px 0 0 -0.25rem;
  display: inline-block;
  vertical-align: middle;
}
.matchedDetailsRouteitem {
  margin-bottom: 1rem;
}
.matchedDetailsRouteitem > h4 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
}
.matchedDetailsRouteitem > h4:before {
  width: 0.75rem;
  height: 0.75rem;
  background: var(--bg-main-level5);
  content: '';
  margin-top: -0.25rem;
  margin-right: 0.375rem;
  display: inline-block;
  vertical-align: middle;
  transform: rotate(45deg);
}
.matchedDetailsRouteitem > p {
  margin: 0 0 0.25rem 0;
  font-size: 0.75rem;
}
.matchedDetailsRouteitemStations {
  margin: 0 0 0.25rem 0;
  padding-left: 0;
  font-size: 0.75rem;
  counter-reset: countee -1;
}
.matchedDetailsRouteitemStations > li {
  display: inline-block;
  margin: 0 0.5rem 0.25rem 0;
  list-style-type: decimal;
  line-height: 1;
  counter-increment: countee;
}
.matchedDetailsRouteitemStations > li:before {
  content: counter(countee) '. ';
  font-weight: bold;
}
.matchedDetailsRouteitemRoutes {
  margin: 0 0 0.25rem 0;
  padding-left: 0;
  font-size: 0.75rem;
}
.matchedDetailsRouteitemRoutes > li {
  display: block;
  margin: 0 0 0.25rem 0;
  padding-left: 0.66rem;
  line-height: 1;
  text-indent: -0.66rem;
}
.matchedDetailsRouteitemRoutes > li:before {
  margin-right: 0.25rem;
  content: '#';
  font-weight: bold;
}
